<template>
    <div>
        <v-flex
            :xs6="$vuetify.breakpoint.mdAndUp"
            :xs12="$vuetify.breakpoint.smAndDown"
        >
            <form-element
                :label="
                    $t(
                        `applications.${$_.upperFirst(
                            type
                        ).toLowerCase()}-housing-status`
                    )
                "
                class="mb-1"
                required
            >
                <v-select
                    class="mt-2"
                    :placeholder="`Choose ${type} housing status`"
                    :label="`Choose ${type} housing status`"
                    solo
                    required
                    :rules="[
                        (v) => !!v || $t('applications.please-select-status'),
                    ]"
                    :items="items"
                    item-text="text"
                    item-value="status"
                    :value="status"
                    return-object
                    @change="statusChange"
                ></v-select>
            </form-element>
        </v-flex>
    </div>
</template>
<script>
export default {
    name: "select-rental-status",
    components: {},
    props: {
        type: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            items: [
                {
                    status: "rent-from-3rd-party",
                    text: this.$t("applications.rent-from-3rd-party"),
                    label: this.$t("applications.landlord"),
                    payment_label: this.$t("applications.monthly-rent"),
                },
                {
                    status: "own-my-home",
                    text: this.$t("applications.own-my-home"),
                    label: this.$t("applications.owner"),
                    payment_label: this.$t("applications.monthly-mortgage"),
                },
                {
                    status: "live-with-family",
                    text: this.$t("applications.live-with-family"),
                    label: this.$t("applications.family-member"),
                    payment_label: this.$t("applications.monthly-rent"),
                },
                {
                    status: "live-with-friend",
                    text: this.$t("applications.live-with-friend"),
                    label: this.$t("applications.friend"),
                    payment_label: this.$t("applications.monthly-rent"),
                },
                {
                    status: "no-address",
                    text: this.$t("applications.no-address", {
                        type: this.$_.startCase(this.type),
                    }),
                    label: this.$t("applications.none"),
                    payment_label: this.$t("applications.monthly-rent"),
                },
            ],
        };
    },
    computed: {},
    methods: {
        statusChange(status) {
            this.$emit("update", status);
        },
    },
};
</script>

<style scoped>
</style>