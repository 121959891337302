var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-flex",
        {
          attrs: {
            xs6: _vm.$vuetify.breakpoint.mdAndUp,
            xs12: _vm.$vuetify.breakpoint.smAndDown
          }
        },
        [
          _c(
            "form-element",
            {
              staticClass: "mb-1",
              attrs: {
                label: _vm.$t(
                  "applications." +
                    _vm.$_.upperFirst(_vm.type).toLowerCase() +
                    "-housing-status"
                ),
                required: ""
              }
            },
            [
              _c("v-select", {
                staticClass: "mt-2",
                attrs: {
                  placeholder: "Choose " + _vm.type + " housing status",
                  label: "Choose " + _vm.type + " housing status",
                  solo: "",
                  required: "",
                  rules: [
                    function(v) {
                      return !!v || _vm.$t("applications.please-select-status")
                    }
                  ],
                  items: _vm.items,
                  "item-text": "text",
                  "item-value": "status",
                  value: _vm.status,
                  "return-object": ""
                },
                on: { change: _vm.statusChange }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }